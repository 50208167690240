<template>
  <div v-if="isLoading">
    <CCard>
      <CCardBody>
        <h2>
          Loading... Please wait...
          <CSpinner
            color="primary"
            style="
              width: 2rem;
              height: 2rem;
              border: 0.125em solid currentColor;
              border-right-color: transparent;
              margin-left: 10px;
            "
          />
        </h2>
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="hasLoadingError">
    <CCard>
      <CCardHeader>
        <h2>Error</h2>
      </CCardHeader>
      <CCardBody>
        An Error occurred while trying to load this task. Please see your system
        administrator to resolve the issue.
        <p class="pt-3">
          <strong>{{ loadingError }}</strong>
        </p>
      </CCardBody>
    </CCard>
  </div>
  <div v-else-if="!isDataFetched">
    <CCard>
      <CCardHeader>
        <h2>Workflow Not Found</h2>
      </CCardHeader>
      <CCardBody>This workflow could not be loaded.</CCardBody>
    </CCard>
  </div>
  <div v-else>
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol col="10">
            <h2>Workflow: {{ workflowTitle }}</h2>
          </CCol>
          <CCol class="text-right">
            <CButtonGroup>
              <CButton
                color="success"
                title="Reload"
                :disabled="isRefreshing"
                @click="reloadData"
              >
                <MIcon name="fa-refresh" :class="{ 'fa-spin': isRefreshing }" />
              </CButton>
              <CButton
                color="primary"
                title="View Definition"
                @click="showWorkflowDefinition"
              >
                <MIcon name="fa-file" />
              </CButton>
              <CButton
                v-if="!workflow.terminal"
                color="warning"
                title="Terminate"
                @click="terminateWorkflow"
              >
                <MIcon name="fa-times" />
              </CButton>
              <!-- <CButton
                color="info"
                title="Restart"
                @click="restartWorkflow"
                v-if="workflow.terminal"
              >
                <MIcon name="fa-fast-backward" />
              </CButton>-->
              <CButton
                v-if="workflow.terminal"
                color="danger"
                title="Delete"
                @click="deleteWorkflow"
              >
                <MIcon name="fa-trash" />
              </CButton>
            </CButtonGroup>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <p v-if="workflow.workflowDescription">
          {{ workflow.workflowDescription }}
        </p>
        <p v-else>
          This is an overview of a specific Workflow instance. Individual steps
          can be overridden, retried or otherwise adjusted.
          <br />Additionally, the entire workflow instance can be restarted or
          terminated if necessary.
        </p>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader>
        <h2>Workflow Tasks</h2>
      </CCardHeader>
      <CCardBody>
        <CDataTable
          :items="taskList"
          :fields="taskFields"
          class="workflow-table-container"
        >
          <template #statusIcon="{ item }">
            <td>
              <CSpinner
                v-if="item.status === 'IN_PROGRESS'"
                color="info"
                size="sm"
              />
              <MIcon
                v-else-if="item.statusIcon"
                :name="item.statusIcon.name"
                :class="item.statusIcon.color"
              />
            </td>
          </template>
          <template #title="{ item }">
            <td>
              <router-link
                v-if="item.enabledActions.view"
                :to="{ name: 'view-task', params: { id: item.taskId } }"
                >{{ item.title }}</router-link
              >
              <template v-else>{{ item.title }}</template>
              <!-- <router-link
                v-else
                :to="{ name: 'view-task-def', params: { name: item.referenceTaskName }}"
              >{{ item.title }}</router-link>-->
            </td>
          </template>
          <template #taskTime="{ item }">
            <td>{{ $format.formatTimestamp(item.taskTime) }}</td>
          </template>
          <template #action="{ item }">
            <td>{{ item.action ? item.action : '' }}</td>
          </template>
          <template #actions="{ item }">
            <td>
              <MButtonSelect
                v-if="
                  item.status !== 'UNSCHEDULED' && item.status !== 'CANCELED'
                "
                :options="makeTaskOptions(item)"
                @click="
                  (value) => {
                    handleActionClick(item, value);
                  }
                "
              />
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { entityLoadingMixin } from '@/mixins/entity-loading-mixin';
import WorkflowTask from '@/WorkflowTask';
import MButtonSelect from '@/components/MButtonSelect';
import api from '@/api';

const TASK_FIELDS = [
  {
    key: 'statusIcon',
    label: '',
  },
  {
    key: 'taskTypeLabel',
    label: 'Type',
  },
  {
    key: 'title',
    label: 'Title',
  },
  {
    key: 'taskTime',
    label: 'Date',
  },
  {
    key: 'actionText',
    label: 'Status',
  },
  {
    key: 'actions',
    label: 'Actions',
  },
];

export default {
  name: 'WorkflowOverview',
  components: {
    MButtonSelect,
  },
  mixins: [entityLoadingMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.dispatch('notifications/addListener', {
        eventType: 'taskUpdated',
        handlerFunction: vm.handleWorkflowUpdated,
      });
      vm.$store.dispatch('notifications/addListener', {
        eventType: 'taskEscalated',
        handlerFunction: vm.handleWorkflowUpdated,
      });
      vm.$store.dispatch('notifications/addListener', {
        eventType: 'workflowUpdated',
        handlerFunction: vm.handleWorkflowUpdated,
      });
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('notifications/removeListener', {
      eventType: 'taskUpdated',
      handlerFunction: this.handleWorkflowUpdated,
    });
    this.$store.dispatch('notifications/removeListener', {
      eventType: 'taskEscalated',
      handlerFunction: this.handleWorkflowUpdated,
    });
    this.$store.dispatch('notifications/removeListener', {
      eventType: 'workflowUpdated',
      handlerFunction: this.handleWorkflowUpdated,
    });
    next();
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dispatchPath: 'workflows/fetchWorkflowDetails',
      isRefreshing: false,
    };
  },
  computed: {
    ...mapGetters('notifications', ['eventSource']),
    ...mapGetters('workflows', ['lastUpdatedWorkflow']),
    workflow() {
      return this.fetchedData;
    },
    workflowTitle() {
      let title = this.workflow.input.clientName;
      if (!this.workflow) return title;
      title = title + ': ' + this.workflow.title || this.workflow.input.title;
      return title;
    },
    workflowActive() {
      return true;
    },
    taskList() {
      return (this.workflow ? this.workflow.tasks || [] : []).filter((task) => {
        if (task.taskDefName === 'JOIN') {
          return false;
        }
        if (task.status === 'COMPLETED' || task.status === 'IN_PROGRESS') {
          return !(
            task.taskDefName == 'FORK' || task.taskDefName == 'FORK_JOIN'
          );
        }
        return true;
      });
    },
    taskFields() {
      return TASK_FIELDS;
    },
  },
  methods: {
    makeTaskOptions(task) {
      let opts = [];
      if (
        task.taskDefName == 'FORK' ||
        task.taskDefName == 'FORK_JOIN' ||
        task.taskDefName == 'JOIN'
      ) {
        return [{ text: 'View TaskDef', value: 'DEFINITION' }];
      }

      if (!task.executed) {
        if (
          task.status === 'ESCALATED' ||
          task.status === 'SCHEDULED' ||
          task.status === 'IN_PROGRESS'
        ) {
          opts.push({ text: 'View', value: 'VIEW' });
          if (!task.userTask) {
            opts.push({ text: 'Resend', value: 'RESEND' });
          }
          opts.push({ text: 'Escalate', value: 'ESCALATE' });
        } else {
          opts.push({ text: 'View TaskDef', value: 'DEFINITION' });
        }
      } else {
        opts.push({ text: 'View', value: 'VIEW' });
        if (task.status !== 'ESCALATED') {
          opts.push({ text: 'Rewind', value: 'REWIND' });
        }
        if (task.userTask) {
          opts.push({ text: 'Rewind & Resubmit', value: 'RESUBMIT' });
        } else {
          opts.push({ text: 'Resend', value: 'RESEND' });
        }
      }

      // TODO: If user is admin...
      //opts.push({ text: 'Admin', value: 'ADMIN' });

      return opts;
    },
    showWorkflowDefinition() {
      this.$router.push({
        name: 'view-workflow-def',
        params: { workflowDefName: this.workflow.workflowDefName },
      });
    },
    terminateWorkflow() {
      if (!this.workflow) return;
      this.$store
        .dispatch('workflows/terminate', this.workflow.workflowId)
        .then(() => {
          this.$swal
            .fire({
              title: 'Success',
              icon: 'success',
              text: 'Your workflow has been successfully terminated.',
            })
            .then(() => {
              this.$router.push({
                name: 'list-workflows',
                params: { status: 'all' },
              });
            });
        });
    },
    restartWorkflow() {
      if (!this.workflow) return;
      this.$store
        .dispatch('workflows/restart', this.workflow.workflowId)
        .then(() => {
          this.$swal
            .fire({
              title: 'Success',
              icon: 'success',
              text: 'Your workflow has been successfully restarted.',
            })
            .then(() => {
              this.$router.push({
                name: 'list-workflows',
                params: { status: 'all' },
              });
            });
        });
    },
    deleteWorkflow() {
      if (!this.workflow) return;
      this.$swal
        .fire({
          icon: 'warning',
          title: 'Confirm Delete',
          text: 'You are about to permanently delete your workflow, are you sure this is what you want to do?',
          showOkButton: true,
          showCancelButton: true,
          okButtonText: 'Yes',
          cancelButtonText: 'No',
        })
        .then((result) => {
          if (result.value) {
            this.$store
              .dispatch('workflows/delete', this.workflow.workflowId)
              .then(() => {
                this.$swal
                  .fire({
                    title: 'Success',
                    icon: 'success',
                    text: 'Your workflow has been permanently deleted.',
                  })
                  .then(() => {
                    this.$router.push({
                      name: 'list-workflows',
                      params: { status: 'all' },
                    });
                  });
              });
          }
        });
    },
    escalateTask(item) {
      // TODO: Make API request to escalate.
    },
    rewindTask(item) {
      // TODO: Make API request to rewind.
    },
    editTask(item) {
      this.$router.push({ name: 'edit-task', params: { id: item.taskId } });
    },
    performTaskRewind(task, resubmit) {
      let req = {
        workflowId: task.workflowId,
        targetTaskId: task.taskId,
        resubmit: resubmit === true,
      };

      return api.workflows.rewind(req);
    },
    resendTask(task) {
      let req = {
        workflowId: task.workflowId,
        taskId: task.taskId,
      };
      return api.workflows.resend(req);
    },
    reloadData(doForce, timeout) {
      if (!doForce && (this.isRefreshing || this.isLoading)) {
        return;
      }
      let timeoutInterval = typeof timeout === 'undefined' ? 1000 : timeout;

      const self = this;
      //this.initializeLoading(self);
      this.isRefreshing = true;
      this.fetchData({ id: this.id })
        .then((data) => {
          self.setData(data, null);
        })
        .catch((err) => {
          self.setData(null, err);
        })
        .finally(() => {
          if (timeoutInterval > 0) {
            setTimeout(() => {
              self.isRefreshing = false;
            }, timeoutInterval);
          } else {
            self.isRefreshing = false;
          }
        });
    },
    handleActionClick(task, event) {
      if (!task || !event) {
        return;
      }

      switch (event) {
        case 'VIEW':
          this.$router.push({
            name: 'view-task',
            params: {
              id: task.taskId,
            },
          });
          break;
        case 'REWIND':
        case 'RESUBMIT':
          this.performTaskRewind(task, 'RESUBMIT' === event).then((res) => {
            this.reloadData(true);
          });
          break;
        case 'RESEND':
          this.resendTask(task).then((res) => {
            this.reloadData(true);
          });
          break;
        case 'ADMIN':
          break;
        default:
          break;
      }
    },
    handleWorkflowUpdated(event) {
      let e = JSON.parse(event.data);
      if (e.workflowId === this.id) {
        this.reloadData(false, 0);
        return;
      }
    },
  },
};
</script>
<style lang="scss">
.workflow-table-container {
  min-height: 275px;
  overflow-y: auto;
  box-sizing: border-box;
  height: calc(70vh - 650px);
}

@media screen and (max-width: 751px) {
  .workflow-table-container {
    height: calc(100vh - 575px);
  }
}

@media screen and (min-width: 752px) {
  .workflow-table-container {
    height: calc(100vh - 490px);
  }
}
</style>
