<template>
  <CButtonGroup style="z-index: unset">
    <CButton
      style="background-color: #e6e6e6"
      @click="$emit('click', primaryOption.value)"
      >{{ primaryOption.text }}</CButton
    >
    <CDropdown
      v-if="hasOtherOptions"
      class="action-select"
      color="primary"
      toggler-text
    >
      <CDropdownItem
        v-for="(option, idx) in otherOptions"
        :key="option.value + idx"
        @click="$emit('click', option.value)"
        >{{ option.text }}</CDropdownItem
      >
    </CDropdown>
    <CDropdown
      v-else
      color="primary"
      class="action-select disabled"
      disabled
      toggler-text
    ></CDropdown>
  </CButtonGroup>
</template>
<style lang="scss">
.action-select button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
<script>
export default {
  name: 'MButtonSelect',

  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  computed: {
    primaryOption() {
      return this.options.find((o) => o.primary) || this.options[0];
    },
    hasOtherOptions() {
      return this.otherOptions.length > 0;
    },
    otherOptions() {
      if (!this.options || this.options.length < 2) {
        return [];
      }

      let primary = this.primaryOption;
      if (primary.primary === true) {
        return this.options.filter((o) => {
          return !o.primary;
        });
      } else {
        return this.options.slice(1);
      }
    },
  },
  methods: {
    primarySelected() {},
  },
};
</script>
